import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  ar: {
    translation: {
      "cat": "الكاتيكيزم",
      "bible": "الكتاب المقدس",
      "site_titlel1": "التعليم المسيحي للكنيسة الكاثوليكية  ",
      "site_titlel2": "",
      "bible_titlel1": "الكتاب المقدس",
      "bible_titlel2": " الترجمة الكاثوليكيّة (اليسوعيّة)",
      "books": "الأسفار",
      "daily": "القراءات اليومية",
      "read": "القراءة",
        "topics": "الموضوعات",
        "search": "البحث",
        "translate_all_checkbox":{
            "part1": "اضغط على رقم الفقرة لعرض الترجمة الانجليزية تحتها،",
            "part2": " أو اعرض للكل",
        },
        "next": "التالي",
        "toc": "الفهرس",
        "previous": "السابق",
        "see_also": "أنظر أيضًا:",
        "back": "رجوع",
        "paragraphs_related_to": " كل الفقرات المتعلقة بـ : ",
        "made_by": "بـ❤ للكنيسة الواحدة الجامعة التي في كل مكان",
        "parts" : "أجزاء",
        "sections": "أقسام",
        "chapters": "فصول",
        "articles": "مقالات",
        "paragraphs": "فقرات قانون الإيمان",
        "numbered": "عناوين مرقمة",
        "paragraph_groups": "مجموعات الفقرات",
        'toc_depth': "عمق الفهرس:",
        "ref_join": "، و",
        "paragraph_no": "الفقرة: ",
        "paragraphs_no": "الفقرات: ",
        "options_search_info": "لمعلومات عن كيفية استخدام البحث، دع صندوق البحث فارغًا أو",
        "click_here": "اضغط هنا",
        "goto_info": "لمعلومات عن الترجمات و البرنامج، ",
        "pre_search_text_paragraphs": "الفقرات التي تحتوي على " ,
        "no_results_paragraphs": "لا يوجد فقرات تحتوى على ",
        "pre_search_text_titles": "العناوين التي تحتوي على " ,
        "no_results_titles": "لا يوجد عناوين تحتوى على ",
        "pre_search_text_topics": "الموضوعات التي تحتوي على " ,
        "no_results_topics": "لا يوجد موضوعات تحتوى على ",
        "results":"عدد النتائج: ",
        "bilingual": "لا يوجد في الكاتيكزم نصوص بلغات مختلطة، برجاء تعديل الكلمة أو الكلمات المطلوب البحث عنها لتكون إما بالعربية فقط أو بالإنجليزية فقط",
        "paragraphs_word": "الفقرات",
        "titles_word": "العناوين",
        "topics_word": "الموضوعات",
        "search_in": "البحث في:",
        "topics_results_info": "اضغط على أي نتيجة لقراءة كل الفقرات المتعلقة بهذا الموضوع",
        "titles_results_info": "اضغط على أي نتيجة لقراءة الكاتيكزم عند هذا العنوان",
        "wrong_paragraph": "لا يوجد في الكاتيكيزم الفقرة ",
        "why_wrong_paragraph" : "، أرقام الفقرات تقع بين 1 و 2865",
        "search_intro1": "يمكنك البدأ في كتابة كلمة (أو كلمات) في صندوق البحث للبحث عنها في الموضوعات و العناوين و نصوص الفقرات.",
        "search_intro2": "اذا كنت تعلم رقم الفقرة التي تريدها، اكتب رقم الفقرة، أو مجموعة أرقام مفصولين بفاصلة (،) أو مسافة فارغة",
        "search_intro3": "لمشاركة فقرة ما أو للحصول على رابط مستقل لها، ابحث عن رقم الفقرة التي تريدها، ثم انسخ الرابط و ارسله. (و ذلك ينطبق ايضًا على أي صفحة في هذا البرنامج. لمشاركتها، انسخ الرابط)",
        "topics_toc": "فهرس الموضوعات",

        "old_testament": "العهد القديم",
        "torah": "التوراة",
        "gen": "سِفْرُ التَّكْوين",
        "ex": "سِفْرُ الخُرُوج",
        "lev": "سِفرُ الأَحْبار",
        "num": "سِفْرُ العَدَد",
        "deut": "سِفْرُ تَثْنِيَة الِاشْتِراع",
        "history": "الأسفار التاريخية",
        "josh": "سِفْرُ يَشوع",
        "judg": "سِفْرُ القُضاة",
        "ruth": "سِفْرُ راعُوت",
        "1sam": "سِفْرُ صموئيل الأوّل",
        "2sam": "سِفْرُ صموئيل الثاني",
        "1kings": "سِفْرُ الملوك الأوّل",
        "2kings": "سِفْرُ الملوك الثّاني",
        "1chron": "سِفْرُ الأخبار الأوّل",
        "2chron": "سِفْرُ الأخبار الثاني",
        "ezra": "سِفْرُ عَزْرا",
        "neh": "سِفْرُ نَحَمْيا",
        "tob": "سِفْرُ طوبيّا",
        "jth": "سِفْرُ يهوديت",
        "est": "سِفْرُ أَسْتير",
        "1macc": "سِفْرُ المكَّابيّين الأوّل",
        "2macc": "سِفْرُ المكَّابيّين الثّاني",
        "wisdom": "أسفار الحكمة",
        "job": "سِفْرُ أيّوب",
        "ps": "سِفْرُ المزامير",
        "prov": "سِفْرُ الأمثال",
        "eccles": "سِفْرُ الجامعة",
        "song": "سِفْرُ نشيد الأناشيد",
        "wisdofsol": "سِفْرُ الحكمة",
        "sir": "سِفْرُ يشوع بن سيراخ",
        "prophets": "الأنبياء",
        "isa": "سِفْرُ أَشَعْيا",
        "jer": "سِفْرُ إِرْمِيا",
        "lam": "سِفْرُ المراثي",
        "bar": "سِفْرُ باروك",
        "ezek": "سِفْرُ حَزْقِيال",
        "dan": "سِفْرُ دانيال",
        "hos": "سِفْرُ هوشع",
        "joel": "سِفْرُ يوئيل",
        "amos": "سِفْرُ عاموس",
        "obad": "سِفْرُ عوبديا",
        "jonah": "سِفْرُ يونان",
        "mic": "سِفْرُ ميخا",
        "nah": "سِفْرُ نحوم",
        "hab": "سِفْرُ حبقّوق",
        "zeph": "سِفْرُ صفنيا",
        "hag": "سِفْرُ حجَّاي",
        "zech-2": "سِفْرُ زكريّا",
        "mal": "سِفْرُ ملاخي",
        "new_testament": "العهد الجديد",
        "gospels": "الأناجيل",
        "matt": "إنجيل مَتّى",
        "mac": "إنجيل مرقس",
        "luke": "إنجيل لوقا",
        "john": "إنجيل يوحنا",
        "acts": "أعمال الرسل",
        "pauls_letters": "رسائل القديس بولس الرسول",
        "rom": "رسالة القدّيس بُولُس إلى أهل رُومة",
        "1cor": "رسالة القدّيس بُولُس الأولى إلى أهل قورنتس",
        "2cor": "رسالة القدّيس بُولُس الثانية إلى أهل قورنتس",
        "gal": "رسالة القدّيس بُولُس إلى أهل غلاطية",
        "eph": "رسالة القدّيس بُولُس إلى أهل أفسُس",
        "phil": "رسالة القدّيس بُولُس إلى أهل فيلبّي",
        "col": "رسالة القدّيس بُولُس إلى أهل قولسّي",
        "1thess": "رسالةُ القدّيس بُولُس الأولى إلى أهل تسالونيقي",
        "2thess": "رسالةُ القدّيس بُولُس الثانِيَة إلى أهل تسالونيقي",
        "1tim": "رسالةُ القدّيس بُولُس الأولى إلى طيموتاوس",
        "2tim": "رسالةُ القدّيس بُولُس الثانية إلى طيموتاوس",
        "titus": "رسالةُ القدّيس بُولُس إلى طيطس",
        "philem": "رسالةُ القدّيس بُولُس إلى فيلمُون",
        "heb": "الرسالة إلى العبرانيّين",
        "general_letters": "الرسائل الجامعة",
        "james": "رسالة القدّيس يعقوب",
        "1pet": "رسالة القدّيس بطرس الأولى",
        "2pet": "رسالة القدّيس بطرس الثانية",
        "1john": "رسالة القدّيس يُوحَنّا الأولى",
        "2john": "رسالة القدّيس يُوحَنّا الثانية",
        "3john": "رسالة القدّيس يُوحَنّا الثالثة",
        "jude": "رسالة القدّيس يهوذا",
        "rev": "رؤيا القدّيس يُوحَنّا",

        "back-to-books": "العودة الى فهرس الأسفار",
        "readings_of": "قراءات يوم: ",

        "mowafek": "الموافق ",
        "from": " من ",

        "Sun": "الأحد",
        "Mon": "الاثنين",
        "Tue": "الثلاثاء",
        "Wed": "الأربعاء",
        "Thu": "الخميس",
        "Fri": "الجمعة",
        "Sat": "السبت",

        "25-12": "25 ديسمبر",
        "26-12": "26 ديسمبر",
        "27-12": "27 ديسمبر",
        "28-12": "28 ديسمبر",
        "29-12": "29 ديسمبر",
        "30-12": "30 ديسمبر",
        "31-12": "31 ديسمبر",
        "1-1": "1 يناير",
        "2-1": "2 يناير",
        "3-1": "3 يناير",
        "4-1": "4 يناير",
        "5-1": "5 يناير",
        "7-1": "7 يناير",
        "8-1": "8 يناير",
        "9-1": "9 يناير",
        "10-1": "10 يناير",
        "11-1": "11 يناير",
        "12-1": "12 يناير",

        "first": "الاسبوع الأول",
        "second": "الاسبوع الثاني",
        "third": "الاسبوع الثالث",
        "fourth": "الاسبوع الرابع",
        "fifth": "الاسبوع الخامس",
        "sixth": "الاسبوع السادس",
        "seventh": "الاسبوع السابع",
        "eighth": "الاسبوع الثامن",
        
        "deneh": "زمن الدنح",
        "som": "زمن الصوم",
        "keyama": "زمن القيامة",
        "rosol": "زمن الرسل",
        "toba": "زمن التوبة (الصيف)",
        "elija": "زمن إيليا",
        "mosa": "زمن موسى",
        "takdes": "زمن تقديس الكنيسة",
        "beshara": "زمن البشارة",
        "melad": "زمن الميلاد",

        "3ed-elmelad": "عيد ميلاد ربّنا يسوع المسيح",
        "tahne2a": "عيد تهنئة العذراء مريم",
        "ra2s-elsana": "عيد الختانة ورأس السنة",
        "3ed-deneh": "عيد الدنح- الظهور (عِماد ربّنا يسوع المسيح )",
        "sa3aneen": "أحد السعانين",
        "5ames-el3ahd": "خميس العهد",
        "elgom3a-el3azema": "الجمعة العظيمة",
        "sabt-elnoor": "سبت النور (ليلة القيامة)",
        "3ed-keyama": "عيد القيامة المجيد",
        "new-sunday": "الأحد الجديد",
        "3ed-so3od": "عيد الصعود",
        "sunday-after-so3od": "الأحد بعد الصعود",
        "3ed-3ansara": "عيد العنصرة (حلول الروح القدس على الرسل)",
        "3ed-elgasad": "عيد الجسد",
        "3ed-eltagally": "عيد التجلّي",
        "3ed-entkal": "عيد انتقال مريم العذراء الى السماء",
        "3ed-elsalib": "عيد الصليب",
        "1st-sun-melad": "الأحد بعد الميلاد",
        "2nd-sun-melad": "الأحد الثاني بعد الميلاد",
        "1st-ba3otha": "اثنين صوم الباعوثة",
        "2nd-ba3otha": "ثلاثاء صوم الباعوثة",
        "3rd-ba3otha": "اربعاء صوم الباعوثة",
    
        "1-read": "القراءة الأولى: ",
        "2-read": "القراءة الثانية: ",
        "3-read": "القراءة الثالثة: ",
        "4-read": "القراءة الرابعة: ",

        "and": " و ",
        "2readings": "تُعلَن لنا كلمة الله في هذا اليوم من خلال قراءتين: ",
        "3readings": "تُعلَن لنا كلمة الله في هذا اليوم من خلال ثلاث قراءات: ",
        "4readings": "تُعلَن لنا كلمة الله في هذا اليوم من خلال أربع قراءات: ",

    }
  },
  en: {
    translation: {
      "cat": "The Catechism",
      "bible": "The Holy Bible",
      "site_titlel1": "Catechism of the Catholic Church",
      "site_titlel2": "",
      "bible_titlel1": "The Holy Bible",
      "bible_titlel2": "",
      "books": "Books",
      "daily": "Daily Readings",
      "read": "Read",
        "topics": "Topics",
        "search": "Search",
        "translate_all_checkbox":{
            "part1": "Press on any paragraph to view its Arabic translation below it,",
            "part2": "or show all",
        },
        "next": "next",
        "toc": "TOC",
        "previous": "previous",
        "see_also": "See Also:",
        "back": "Back",
        "paragraphs_related_to": "All Paragraphs Related to : ",
        "made_by": "Made with ❤ to the One Holy Catholic Church that is in every place",
        "parts" : "Parts",
        "sections": "Sections",
        "chapters": "Chapters",
        "articles": "Articles",
        "paragraphs": "Paragraphs of the Creed",
        "numbered": "Numbered",
        "paragraph_groups": "Paragraph Groups",
        'toc_depth': "TOC Depth:",
        "ref_join": ", ",
        "paragraph_no": "Paragraph: ",
        "paragraphs_no": "Paragraphs: ",
        "options_search_info": "For info about how to use the search functionality, you can empty the search bar or",
        "click_here": "click here",
        "goto_info": "For info about the translations and the application, ",
        "pre_search_text_paragraphs": "Paragraphs that contain " ,
        "no_results_paragraphs": "No paragraphs contain ",
        "pre_search_text_titles": "Titles that contain " ,
        "no_results_titles": "No Titles contain ",
        "pre_search_text_topics": "Topics that contain " ,
        "no_results_topics": "No Topics contain ",
        "results":"no. of results: ",
        "bilingual": "There is no bilingual text in the catechism, please modify your search query to include only Arabic word/s or only English word/s",
        "paragraphs_word": "Paragraphs",
        "titles_word": "Titles",
        "topics_word": "Topics",
        "search_in": "Search in:",
        "topics_results_info": "Press on any result to read all paragraphs related to this topic",
        "titles_results_info": "press on any title to read the catechism at this title",
        "wrong_paragraph": "Paragraph number ",
        "why_wrong_paragraph" : " doesn't exist, paragraphs in the catechism has a number between 1 and 2865",
        "search_intro1": "You can start to type a word (or words) to search for it in topics, titles, and paragraphs text",
        "search_intro2": "If you know the number of the paragraph you want, write the paragraph number, or a sequance of numbers separated by a comma(,) or a space",
        "search_intro3": "To share a specific paragraph, or to have a standalone link for it, search for the number of the paragraph you want, then copy the page link (this also applies to any page you see. To share it, copy the page link", 
        "topics_toc": "Topics index",

        "back-to-books": "Back to Books",

        // "readings_of": "Reading of Day: ",

        // "mowafek": "",
        // "from": " from the ",

        // "Sun": "Sunday",
        // "Mon": "Monday",
        // "Tue": "Tuesday",
        // "Wed": "Wednesday",
        // "Thu": "Thursday",
        // "Fri": "Friday",
        // "Sat": "Saturday",

        // "first": "First Week",
        // "second": "Second Week",
        // "third": "Third Week",
        // "fourth": "Fourth Week",
        // "fifth": "Fifth Week ",
        // "seventh": "Seventh Week",
        // "eighth": "Eighth week ",

        
        // "deneh": "Epiphany Season",
        // "som": "Lent Season",
        // "keyama": "Easter Season",
        // "rosol": "Apostles Season",
        // "toba":  "Repentance Season",
        // "elija": "Elija Season",
        // "mosa": "Moses Season",
        // "takdes": "Consecration of The Church Season",
        // "beshara": "The Annunciation Season",


    }
  }
};

// let myDetector = {
//   name: 'myDetector',

//   lookup(options) {
//     // options -> are passed in options
//     // if not in search param return arabic
//     // else return what is in search param
//     // let params = new URL(document.location).searchParams;
//     // let lng = params.get("lng"); // is the string "Jonathan Smith".
//      console.log("entered my detector")
//     return 'en';
//   },

//   cacheUserLanguage(lng, options) {
//     // options -> are passed in options
//     // lng -> current language, will be called after init and on changeLanguage
//     // store it
//     // store in search params

//   },
// };

const languageDetector = new LanguageDetector();
// languageDetector.addDetector(myDetector);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(languageDetector)
  .init({
    detections: {
      order: ['querystring'],
      lookupQuerystring: 'lng',
      //caches: ['querystring'],
      //excludeCacheFor: ['en', 'ar'], // languages to not persist (cookie, localStorage)


    
    },
    resources,
    //lng: "ar", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'ar',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;